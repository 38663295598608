import React from "react";

const Ztest2 = () => {
    return (
        <div className="app-content">
            <h1>TEST</h1>
            <img src="/assets/computer_guy_full.svg" alt="svg" />
        </div>
    );
};

export default Ztest2;